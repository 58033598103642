import React, { useState } from 'react';
import CardHeader from '../CardHeader';
import SliderButton from '../SliderButton';
import ProgressBar from '../ProgressBar';
import ComplexQuestion from '../ComplexQuestion';
import questions from './questions.json';
import { IDemographics } from '../../types';
import { useTranslation } from '../../hooks';

const Demographics: React.FC<IDemographics> = ({
	active = '',
	barValue,
	handleUpdateAnswers,
}) => {
	const { t } = useTranslation();
	const [answers, setAnswers] = useState<any>([]);

	const handleSetAnswer = (e: any) => {
		const questionId = Number(e.target.attributes.getNamedItem('questionid').value);
		const answer = e.target.value;
		const newAnswer = {
			question_id: questionId,
			answer,
		};

		if (answers.some((a: any) => a.question_id === questionId)) {
			const answersArr: any = [...answers];
			const index = answersArr.findIndex((el: any) => el.question_id === questionId);
			answersArr[index] = newAnswer;
			setAnswers(answersArr);
		} else {
			setAnswers([...answers, newAnswer]);
		}
	};

	const handleNext = () => {
		handleUpdateAnswers({ group: 'demographics', answers, questions_size: questions.length });
	};

	return (
		<div className={`demographics carousel-item ${active}`}>
			<div className="card text-center">
				<CardHeader title={t('components:demographics.title')} subtitle={t('components:demographics.subtitle')} href="#pageCarouselIndicator" />
				<div className="card-body">
					<ProgressBar completed={barValue} />
					<div className="mt-auto mb-auto">
						<p className="card-text text-left">
							{t('components:demographics.wellDone')}
						</p>
					</div>
					<div className="mt-3 mb-auto">
						{questions.map((question: any) => (
							<ComplexQuestion
								key={question.id}
								question={question}
								handleSetAnswer={handleSetAnswer}
							/>
						))}
					</div>
					<SliderButton handleClick={handleNext} />
				</div>
			</div>
		</div>
	);
};

export default Demographics;

import React from 'react';
import { useTranslation } from '../../hooks';

const Spinner: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className="overlay">
			<div className="d-flex justify-content-center">
				<div className="spinner-border m-5" role="status" aria-hidden="true">
					<span className="sr-only">{t('components:spinner.loading')}...</span>
				</div>
			</div>
			<div>{t('components:spinner.wellBeingIndexSurvey')}...</div>
		</div>
	);
};

export default Spinner;

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import BaseLine from '../pages/Baseline';
import Exit from '../pages/Exit';
import Navbar from '../components/Navbar';
import NotFound from '../components/NotFound';
import Report from '../pages/Report';
import '../App.scss';

library.add(fal);

const Routes: React.FC = () => (
	<Router>
		<Navbar />
		<Switch>
			<Route exact path="/:userId" component={BaseLine} />
			<Route exact path="/exit/:userId" component={Exit} />
			<Route exact path="/report/:userId" component={Report} />
			<Route component={NotFound} />
		</Switch>
	</Router>
);

export default Routes;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import fr from './locales/fr-ca.json';

export const availableLanguages = [
	{ name: 'English', code: 'en-US' },
	{ name: 'French', code: 'fr-CA' },
];

export const defaultNS = 'en-US';
export const resources = {
	'en-US': en,
	'fr-CA': fr,
};

i18n.use(initReactI18next).init({
	lng: navigator.language,
	ns: 'en-US',
	fallbackLng: 'en-US',
	defaultNS,
	resources,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;

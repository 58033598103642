import { useState, useEffect } from 'react';
import axios from 'axios';
import { IResult, ISurveyData } from '../types';

const initialState = {
	baseline: [],
	exit: [],
};

export const useSurveyData = (emailMd5: string) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState<ISurveyData>(initialState);
	const [baselineData, setBaselineData] = useState<any>();
	const [exitData, setExitData] = useState<any>();

	const formatApiResponse = (response: IResult) => {
		const formattedResponse = response.answers.map((answer: any) => {
			const newAnswers = answer.answers.map((ans: any) => ({
				...ans,
				question_id: Number(ans.question_id),
			}));

			return ({
				...answer,
				answers: newAnswers,
				questions_size: newAnswers.length,
			});
		});
		return formattedResponse;
	};

	useEffect(() => {
		const getReportData = async () => {
			try {
				const apiResult = await axios.get(`${process.env.REACT_APP_WBS_API_URL}/report-integration/${emailMd5}`);
				setData(apiResult.data);
				setLoading(false);
				return;
			} catch (error) {
				console.error('Error on getting survey data', error);
			}
			setData(initialState);
			setLoading(false);
		};
		if (emailMd5) {
			getReportData();
		}
	}, [emailMd5]);

	useEffect(() => {
		if (data.baseline.length > 0) {
			const baselineAnswersAmount = data.baseline.length;
			const lastBaselineAnswer = data.baseline[baselineAnswersAmount - 1];
			const formattedBaselineAnswers = formatApiResponse(lastBaselineAnswer);
			setBaselineData({
				answers: formattedBaselineAnswers,
				startedAt: lastBaselineAnswer.startedAt,
			});
		}

		if (data.exit.length > 0) {
			const exitAnswersAmount = data.exit.length;
			const lastExitAnswer = data.exit[exitAnswersAmount - 1];
			const formattedExitAnswers = formatApiResponse(lastExitAnswer);
			setExitData({
				answers: formattedExitAnswers,
				startedAt: lastExitAnswer.startedAt,
			});
		}
	}, [data]);

	return {
		loading, data, baselineData, exitData,
	};
};

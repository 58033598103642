import React from 'react';
import useTranslation from '../../hooks/useTranslation';
import { ICardHeader } from '../../types';

const CardHeader: React.FC<ICardHeader> = ({
	title = '',
	subtitle = '',
	href = '',
	titleStyle = '',
	titleId,
}) => {
	const { t } = useTranslation();

	return (
		<div className="card-header">
			{href && (
				<a className="carousel-control-prev" href={href} role="button" data-slide="prev">
					<span className="carousel-control-prev-icon" aria-hidden="true" />
					<span className="sr-only">{t('components:cardHeader.previous')}</span>
				</a>
			)}
			<p id={titleId} className={titleStyle}>
				{title}
				{subtitle && (
					<span>{subtitle}</span>
				)}
			</p>
		</div>
	);
};
export default CardHeader;

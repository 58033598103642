import React from 'react';
import CardHeader from '../CardHeader';

import ContinueToWeb from '../ContinueToWeb';
import { ISummary } from '../../types';
import { useTranslation } from '../../hooks';

const Summary: React.FC<ISummary> = ({ result, saved = false }) => {
	const { t } = useTranslation();
	if (!saved) return null;
	if (result.answers.length === 0) return null;

	const calculateAvg = (group: any) => {
		const groupAnswers = result.answers.find((a: any) => a.group === group);
		if (!groupAnswers) return 0;
		return Number(groupAnswers.answers.reduce((acc: number, cur: any) => cur.answer + acc, 0) / groupAnswers.questions_size).toFixed(2);
	};

	const calculateAvgPerQuestionArray = (group: any, questionsToInclude: any) => {
		const groupAllAnswers = result.answers.find((a: any) => a.group === group);
		const groupAnswers = groupAllAnswers.answers.filter((a: any) => questionsToInclude.includes(a.question_id));
		if (!groupAnswers) return 0;
		return Number(groupAnswers.reduce((acc: number, cur: any) => cur.answer + acc, 0) / questionsToInclude.length).toFixed(2);
	};

	const wellbeingAvg = calculateAvg('well_being');
	const selfReflectionAvg = calculateAvg('self_reflection');
	const selfInsightAvg = calculateAvg('self_insight');
	const mindfulnessAvg = calculateAvg('mindfulness');
	const lifeSatisfactionAvg = calculateAvg('life_satisfaction');
	const lifeMeaningAvg = calculateAvgPerQuestionArray('life_meaning_happiness', [1, 2, 3]);
	const lifeHappinessAvg = calculateAvgPerQuestionArray('life_meaning_happiness', [4, 5, 6]);
	const lifeEngagementAvg = calculateAvg('life_engagement');

	return (
		<div className="carousel-item">
			<div className="card text-center">
				<CardHeader
					titleId="wbs-baseline-summary"
					title={t('components:summary.title')}
				/>
				<div className="card-body">
					<div className="mt-auto mb-auto">
						<p className="card-text text-left">{t('components:summary.allTheWellBeingMeasures')}</p>
						<h6 className="card-text text-left">
							{t('components:summary.wellBeing')}: <span>{wellbeingAvg}</span>
						</h6>
						<p className="card-text text-left">
							{t('components:summary.wellBeingWasMeasured')}
							{t('components:summary.thisScaleFocusesOn')}
							{t('components:summary.generallyGood')}
							{t('components:summary.ifYouRatedYourself')} {t('components:summary.click')}{' '}
							<a href="https://link.springer.com/article/10.1186/1477-7525-5-63" target="_blank" rel="noopener noreferrer">
								{t('components:summary.here')}{' '}
							</a>
							{t('components:summary.moreAboutWarwickEdinburgh')}
						</p>
						<h6 className="card-text text-left mb-0 pb-0">
							{t('components:summary.selfReflection')}: <span>{selfReflectionAvg}</span>
						</h6>
						<h6 className="card-text text-left">
							{t('components:summary.selfInsight')}: <span>{selfInsightAvg}</span>
						</h6>
						<p className="card-text text-left">
							{t('components:summary.selfReflectionAndSelfInsight')}
							{t('components:summary.selfReflectionRefersTo')}
							{t('components:summary.theTendencyToWant')}
							{t('components:summary.bothOfTheseTendencies')} {t('components:summary.click')}{' '}
							<a
								href="https://www.researchgate.net/publication/298348641_The_self-reflection_and_insight_scale_A_new_measure_of_private_self-consciousness"
								target="_blank"
								rel="noopener noreferrer"
							>
								{t('components:summary.here')}{' '}
							</a>
							{t('components:summary.moreAboutSelfReflection')}
						</p>
						<h6 className="card-text text-left">
							{t('components:summary.mindfulness')}: <span>{mindfulnessAvg}</span>
						</h6>
						<p className="card-text text-left">
							{t('components:summary.mindfulnessWasMeasured')}
							{t('components:summary.itMeasuresHowMuchAttention')}
							{t('components:summary.aLowerScoreIsAssociatedWith')}
							{t('components:summary.payingMoreAttention')}
							{t('components:summary.click')}{' '}
							<a href="http://wisebrain.org/papers/MindfulnessWell-Being.pdf" target="_blank" rel="noopener noreferrer">
								{t('components:summary.here')}{' '}
							</a>
							{t('components:summary.moreAboutMindfullAttention')}
						</p>
						<h6 className="card-text text-left mb-0 pb-0">
							{t('components:summary.lifeMeaning')}: <span>{lifeMeaningAvg}</span>
						</h6>
						<h6 className="card-text text-left">
							{t('components:summary.lifeHapiness')}: <span>{lifeHappinessAvg}</span>
						</h6>
						<p className="card-text text-left">
							{t('components:summary.lifeMeaningAndLifeHapiness')}
							{t('components:summary.forSomePeople')}
							{t('components:summary.goodHeslthIsAlsoImportant')}
							{t('components:summary.beingStick')}
							{t('components:summary.click')}{' '}
							<a href="https://aeon.co/essays/what-is-better-a-happy-life-or-a-meaningful-one" target="_blank" rel="noopener noreferrer">
								{t('components:summary.here')}{' '}
							</a>
							{t('components:summary.moreAboutLifeHapiness')}
						</p>
						<h6 className="card-text text-left">
							{t('components:summary.lifeSatisfaction')}: <span>{lifeSatisfactionAvg}</span>
						</h6>
						<p className="card-text text-left">
							{t('components:summary.theSatisfactionWithLife')}
							{t('components:summary.individualsSeemToHave')}
							{t('components:summary.click')}{' '}
							<a href="https://emmons.faculty.ucdavis.edu/wp-content/uploads/sites/90/2015/08/1985_5-SWLS.pdf" target="_blank" rel="noopener noreferrer">
								{t('components:summary.here')}{' '}
							</a>
							{t('components:summary.and')}{' '}
							<a href="https://www.researchgate.net/publication/7404119_The_Satisfaction_With_Life_Scale" target="_blank" rel="noopener noreferrer">
								{t('components:summary.here')}{' '}
							</a>
							{t('components:summary.moreAboutSatisfaction')}
						</p>
						<h6 className="card-text text-left">
							{t('components:summary.lifeEngagement')}: <span>{lifeEngagementAvg}</span>
						</h6>
						<p className="card-text text-left">
							{t('components:summary.theLifeEngagementTest')}
							{t('components:summary.differentPeopleValue')}
							{t('components:summary.havingAHighLifeEngagement')}
							{t('components:summary.click')}{' '}
							<a href="https://link.springer.com/article/10.1007/s10865-005-9044-1" target="_blank" rel="noopener noreferrer">
								{t('components:summary.here')}{' '}
							</a>
							{t('components:summary.lifeEngagement')}
						</p>
						<section className="mt-3 mb-3">
							<h6 className="card-text">{t('components:summary.toGetStarted')}:</h6>
						</section>

						<ContinueToWeb />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Summary;

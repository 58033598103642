import React, { Component, createContext, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

// create the context
export const Auth0Context = createContext();

// export the context as useAuth0
export const useAuth0 = () => useContext(Auth0Context);

function getUrlParameter(name) {
	name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
	const results = regex.exec(window.location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// create a provider
export class Auth0Provider extends Component {
	state = {
		auth0Client: null,
		isLoading: true,
		isAuthenticated: false,
		user: null,
	};

	config = {
		domain: process.env.REACT_APP_AUTH0_DOMAIN,
		client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
		redirect_uri: window.location.origin + window.location.search,
		audience: process.env.REACT_APP_AUTH0_AUDIENCE,
		scope: 'openid email app_metadata user_metadata',
		display: (window.location.pathname === '/signup') ? 'signUp' : 'login',
		hashCleanup: false,
	};

	componentDidMount() {
		this.initializeAuth0();
	}

	getUserWithToken = async () => {
		const isAuthenticated = await this.state.auth0Client.isAuthenticated();
		if (!isAuthenticated) return null;

		const user = await this.state.auth0Client.getUser();
		if (user != null) {
			const token = await this.state.auth0Client.getTokenSilently();
			sessionStorage.setItem('CheckingIn.token', token);
		}
		return user;
	}

	// initialize the auth0 library
	initializeAuth0 = async () => {
		const auth0Client = await createAuth0Client(this.config);
		this.setState({ auth0Client });

		if (window.location.search.includes('continue=')) {
			sessionStorage.setItem('CheckingIn.continue', getUrlParameter('continue'));
		}

		// check to see if they have been redirected after login
		if (window.location.search.includes('code=')) {
			return this.handleRedirectCallback();
		}

		const isAuthenticated = await this.state.auth0Client.isAuthenticated();

		const user = await this.getUserWithToken();
		this.setState({
			auth0Client, isLoading: false, isAuthenticated, user,
		});
	};

	// handle the authentication callback
	handleRedirectCallback = async () => {
		this.setState({ isLoading: true });

		await this.state.auth0Client.handleRedirectCallback();
		const user = await this.getUserWithToken();
		this.setState({ user, isAuthenticated: true, isLoading: false });
		window.history.replaceState({}, document.title, window.location.pathname);
	};

	render() {
		const {
			auth0Client, isLoading, isAuthenticated, user,
		} = this.state;
		const { children } = this.props;

		const configObject = {
			isLoading,
			isAuthenticated,
			user,
			loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
			getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
			getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
			logout: (...p) => {
				auth0Client.logout(...p);
				localStorage.clear();
				sessionStorage.clear();
			},
		};

		return (
			<Auth0Context.Provider value={configObject}>
				{children}
			</Auth0Context.Provider>
		);
	}
}

export const CONSTANTS = {
	WELCOME: 'welcome',
	WELL_BEING: 'well_being',
	SELF_REFLECTION: 'self_reflection',
	SELF_INSIGHT: 'self_insight',
	MINDFULNESS: 'mindfulness',
	LIFE_SATISFACTION: 'life_satisfaction',
	LIFE_MEANING_HAPPINESS: 'life_meaning_happiness',
	LIFE_ENGAGEMENT: 'life_engagement',
	CHECKINGIN_ENGAGEMENT: 'checkingin_engagement',
	DEMOGRAPHICS: 'demographics',
	COMPLETION: 'completion',
	ACTIVE: 'active',
	EMPTY_STRING: '',
};

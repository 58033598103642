import React from 'react';
import { useTranslation } from '../../hooks';
import SliderButton from '../SliderButton';

const ContinueToWeb: React.FC = () => {
	const { t } = useTranslation();
	const { search } = window.location;
	const params = new URLSearchParams(search);
	const continueLink = params.get('continue');
	if (!continueLink) return null;

	const CI_WEB_URL = `${process.env.REACT_APP_CI_WEB_URL}well-being-survey`;

	return (
		<SliderButton label={t('components:continueToWeb.goBack')} hrefLink={CI_WEB_URL} />
	);
};

export default ContinueToWeb;

import { useEffect, useCallback, useState } from 'react';
import {
	useTranslation as useTranslationI18Next,
	TFunction,
	Namespace,
} from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useStickyState from './useStickyState';

interface TranslationState {
	userLanguage: string;
}

interface IReturn {
	userLanguage: string;
	t: TFunction<Namespace>;
	switchLanguage: (lang: string) => void;
}

const DEFAULT_LANGUAGE = 'en-US';

const useTranslation = (): IReturn => {
	const location = useLocation();
	const path = location.pathname.split('/');
	const user = path[path.length - 1];
	const { t, i18n } = useTranslationI18Next();
	const [data, setData] = useState<TranslationState>({ userLanguage: DEFAULT_LANGUAGE });
	const [userLanguage, setUserLanguage] = useStickyState(DEFAULT_LANGUAGE, `${user}:ci_wbs_user_language`);

	const switchLanguage = useCallback((lang: string): void => {
		i18n.changeLanguage(lang);
		setUserLanguage(lang);
	}, [i18n, setUserLanguage]);

	const initTranslation = useCallback((): void => {
		if (userLanguage) {
			i18n.changeLanguage(userLanguage);
			setData({ userLanguage });
		}
	}, [i18n, userLanguage]);

	useEffect(() => {
		initTranslation();
	}, [initTranslation]);

	return {
		t,
		switchLanguage,
		userLanguage: data.userLanguage,
	};
};

export default useTranslation;

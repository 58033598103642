import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Welcome from '../../components/Welcome';
import CompletionExit from '../../components/CompletionExit';
import Questions from '../../components/Questions';
import Demographics from '../../components/Demographics';
import Spinner from '../../components/Spinner';
import useStickyState from '../../hooks/useStickyState';
import { useTranslation } from '../../hooks';
import { useSurveyData } from '../../hooks/useSurveyData';
import { CONSTANTS } from '../../shared/constants';
import { IParams } from '../../types';
import '../../css/questionnaire.scss';

interface IResult {
	user_id: string;
	startedAt: Date;
	answers: any;
	finishedAt?: Date;
}

const Exit: React.FC = () => {
	const { userId } = useParams<IParams>();
	const { t } = useTranslation();
	const { exitData, loading } = useSurveyData(userId);
	const [readyToSave, setReadyToSave] = useState(false);
	const [answers, setAnswers] = useStickyState([], `${userId}:ci_wbs_exit_answers`);
	const [saved, setSaved] = useStickyState(false, `${userId}:ci_wbs_exit_answers_saved`);
	const [startedAt, setStartedAt] = useStickyState(new Date(), `${userId}:ci_wbs_exit_started_at`);

	const result: IResult = {
		user_id: userId,
		startedAt,
		answers,
	};

	useEffect(() => {
		if (exitData) {
			setAnswers(exitData.answers);
			setStartedAt(exitData.startedAt);
			if (exitData.answers.length > 0) {
				setSaved(true);
			}
		}
	}, [exitData, setAnswers, setSaved, setStartedAt]);

	useEffect(() => {
		const saveBaseline = () => {
			result.finishedAt = new Date();

			axios.post(`${process.env.REACT_APP_WBS_API_URL}exit`, result).then((res) => {
				console.log(res);
				console.log(res.data);
			});
			setSaved(true);
		};
		if (readyToSave && !saved) {
			saveBaseline();
		}
	}, [readyToSave, saved, result, setSaved]);

	const handleUpdateAnswers = (answer: any) => {
		if (answers.some((a: any) => a.group === answer.group)) {
			const answersArr = [...answers];
			const index = answersArr.findIndex(el => el.group === answer.group);
			answersArr[index] = answer;

			setAnswers(answersArr);
		} else {
			setAnswers([...answers, answer]);
		}

		// Auto saves when reaching life engagement, that way we don't need a save button
		if (answer.group === CONSTANTS.DEMOGRAPHICS && !saved) {
			setReadyToSave(true);
		}
	};

	let activeTab: any;

	if (!answers.some((a: any) => a.group === CONSTANTS.WELL_BEING)) {
		activeTab = CONSTANTS.WELCOME;
	} else if (!answers.some((a: any) => a.group === CONSTANTS.SELF_REFLECTION)) {
		activeTab = CONSTANTS.SELF_REFLECTION;
	} else if (!answers.some((a: any) => a.group === CONSTANTS.SELF_INSIGHT)) {
		activeTab = CONSTANTS.SELF_INSIGHT;
	} else if (!answers.some((a: any) => a.group === CONSTANTS.MINDFULNESS)) {
		activeTab = CONSTANTS.MINDFULNESS;
	} else if (!answers.some((a: any) => a.group === CONSTANTS.LIFE_SATISFACTION)) {
		activeTab = CONSTANTS.LIFE_SATISFACTION;
	} else if (!answers.some((a: any) => a.group === CONSTANTS.LIFE_MEANING_HAPPINESS)) {
		activeTab = CONSTANTS.LIFE_MEANING_HAPPINESS;
	} else if (!answers.some((a: any) => a.group === CONSTANTS.LIFE_ENGAGEMENT)) {
		activeTab = CONSTANTS.LIFE_ENGAGEMENT;
	} else if (!answers.some((a: any) => a.group === CONSTANTS.CHECKINGIN_ENGAGEMENT)) {
		activeTab = CONSTANTS.CHECKINGIN_ENGAGEMENT;
	} else if (!answers.some((a: any) => a.group === CONSTANTS.DEMOGRAPHICS)) {
		activeTab = CONSTANTS.DEMOGRAPHICS;
	} else {
		activeTab = CONSTANTS.COMPLETION;
	}

	function getAnswers(group: any) {
		return answers.some((a: any) => a.group === group) ? answers.find((ca: any) => ca.group === group).answers : null;
	}

	const wellbeingAnswers = getAnswers(CONSTANTS.WELL_BEING);
	const selfReflectionAnswers = getAnswers(CONSTANTS.SELF_REFLECTION);
	const selfInsightAnswers = getAnswers(CONSTANTS.SELF_INSIGHT);
	const mindfulnessAnswers = getAnswers(CONSTANTS.MINDFULNESS);
	const lifeSatisfactionAnswers = getAnswers(CONSTANTS.LIFE_SATISFACTION);
	const lifeMeaningHappinessAnswers = getAnswers(CONSTANTS.LIFE_MEANING_HAPPINESS);
	const lifeEngagementAnswers = getAnswers(CONSTANTS.LIFE_ENGAGEMENT);
	const checkingInEngagementAnswers = getAnswers(CONSTANTS.CHECKINGIN_ENGAGEMENT);

	const welcomeContent = {
		title: t('pages:exit.welcomeToStudyExitSession'),
		description: [
			t('pages:exit.youHaveCompleted'),
		],
	};

	const isActiveTabOf = (tabName: string) => (activeTab === tabName ? CONSTANTS.ACTIVE : CONSTANTS.EMPTY_STRING);

	if (loading) {
		return <Spinner />;
	}

	return (
		<section className="questionnaire">
			<div className="container">
				<div id="pageCarouselIndicator" className="carousel slide" data-interval="false" data-touch="false">
					<ol className="carousel-indicators">
						{!saved && (
							<>
								<li data-target="#pageCarouselIndicator" className={!answers.length ? CONSTANTS.ACTIVE : CONSTANTS.EMPTY_STRING} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.WELL_BEING)} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.SELF_REFLECTION)} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.SELF_INSIGHT)} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.MINDFULNESS)} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.LIFE_SATISFACTION)} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.LIFE_MEANING_HAPPINESS)} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.LIFE_ENGAGEMENT)} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.CHECKINGIN_ENGAGEMENT)} />
								<li data-target="#pageCarouselIndicator" className={isActiveTabOf(CONSTANTS.DEMOGRAPHICS)} />
							</>
						)}
					</ol>
					<div className="carousel-inner card">
						<Welcome active={isActiveTabOf(CONSTANTS.WELCOME)} content={welcomeContent} />
						<Questions
							active={isActiveTabOf(CONSTANTS.WELL_BEING)}
							cachedAnswers={wellbeingAnswers}
							title={t('pages:exit.wellBeing')}
							subtitle={`${t('pages:exit.questions')} 1 ${t('pages:exit.through')} 14`}
							group={CONSTANTS.WELL_BEING}
							showExperienceMessage
							barValue={0}
							handleUpdateAnswers={handleUpdateAnswers}
						/>
						<Questions
							active={isActiveTabOf(CONSTANTS.SELF_REFLECTION)}
							cachedAnswers={selfReflectionAnswers}
							title={t('pages:exit.selfReflection')}
							subtitle={`${t('pages:exit.questions')} 15 ${t('pages:exit.through')} 26`}
							group={CONSTANTS.SELF_REFLECTION}
							barValue={15}
							handleUpdateAnswers={handleUpdateAnswers}
						/>
						<Questions
							active={isActiveTabOf(CONSTANTS.SELF_INSIGHT)}
							cachedAnswers={selfInsightAnswers}
							title={t('pages:exit.selfInsight')}
							subtitle={`${t('pages:exit.questions')} 27 ${t('pages:exit.through')} 34`}
							group={CONSTANTS.SELF_INSIGHT}
							barValue={30}
							handleUpdateAnswers={handleUpdateAnswers}
						/>
						<Questions
							active={isActiveTabOf(CONSTANTS.MINDFULNESS)}
							cachedAnswers={mindfulnessAnswers}
							title={t('pages:exit.mindfulness')}
							subtitle={`${t('pages:exit.questions')} 35 ${t('pages:exit.through')} 49`}
							group={CONSTANTS.MINDFULNESS}
							barValue={45}
							handleUpdateAnswers={handleUpdateAnswers}
						/>
						<Questions
							active={isActiveTabOf(CONSTANTS.LIFE_SATISFACTION)}
							cachedAnswers={lifeSatisfactionAnswers}
							title={t('pages:exit.mindfulness')}
							subtitle={`${t('pages:exit.questions')} 50 ${t('pages:exit.through')} 54`}
							group={CONSTANTS.LIFE_SATISFACTION}
							barValue={50}
							handleUpdateAnswers={handleUpdateAnswers}
						/>
						<Questions
							active={isActiveTabOf(CONSTANTS.LIFE_MEANING_HAPPINESS)}
							cachedAnswers={lifeMeaningHappinessAnswers}
							title={t('pages:exit.lifeMeaning')}
							subtitle={`${t('pages:exit.questions')} 55 ${t('pages:exit.through')} 60`}
							group={CONSTANTS.LIFE_MEANING_HAPPINESS}
							barValue={60}
							handleUpdateAnswers={handleUpdateAnswers}
						/>
						<Questions
							active={isActiveTabOf(CONSTANTS.LIFE_ENGAGEMENT)}
							cachedAnswers={lifeEngagementAnswers}
							title={t('pages:exit.lifeEngagement')}
							subtitle={`${t('pages:exit.questions')} 61 ${t('pages:exit.through')} 66`}
							group={CONSTANTS.LIFE_ENGAGEMENT}
							barValue={70}
							handleUpdateAnswers={handleUpdateAnswers}
						/>
						<Questions
							active={isActiveTabOf(CONSTANTS.CHECKINGIN_ENGAGEMENT)}
							cachedAnswers={checkingInEngagementAnswers}
							title={t('pages:exit.checkingInEngagement')}
							subtitle={`${t('pages:exit.questions')} 67 ${t('pages:exit.through')} 71`}
							description={t('pages:exit.overThePast')}
							group={CONSTANTS.CHECKINGIN_ENGAGEMENT}
							barValue={80}
							handleUpdateAnswers={handleUpdateAnswers}
						/>
						<Demographics active={isActiveTabOf(CONSTANTS.DEMOGRAPHICS)} handleUpdateAnswers={handleUpdateAnswers} barValue={90} />
						<CompletionExit
							result={result}
							active={isActiveTabOf(CONSTANTS.COMPLETION)}
							barValue={100}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Exit;

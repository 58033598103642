import React from 'react';
import { useTranslation } from '../../hooks';
import CardHeader from '../CardHeader';

const NotFound: React.FC = () => {
	const { t } = useTranslation();

	return (
		<section>
			<div className="container">
				<div className="card text-center">
					<CardHeader title="Oops!" />
					<div className="card-body">
						<div className="mt-auto mb-auto">
							<div>
								<h5 className="card-text">{t('components:notFound.sorryWeCouldNotFind')}</h5>
								<p className="card-text">
									{t('components:notFound.areYouTryingToTakeTheSurvey')}
									<br />
									<a style={{ color: '#3c7778' }} href="mailto:hello@checkingin.co?Subject=Wellbeing%20Index%20Survey%20Request" target="_top">
										{t('components:notFound.reachOut')}
									</a>{' '}
									{t('components:notFound.soWeCanArrangeIt')}
								</p>
							</div>
							<div className="sexy_line" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default NotFound;

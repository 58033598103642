import React, { useState, useEffect } from 'react';
import CardHeader from '../CardHeader';
import TableQuestionnaire from '../TableQuestionnaire';
import AccordionQuestionnaire from '../AccordionQuestionnaire';
import ProgressBar from '../ProgressBar';
import SliderButton from '../SliderButton';
import allQuestionsFile from './data/allQuestions.json';
import { IQuestionComponent } from '../../types';
import { useTranslation } from '../../hooks';

const Questions: React.FC<IQuestionComponent> = ({
	title,
	subtitle,
	description = '',
	group,
	cachedAnswers = [],
	barValue,
	handleUpdateAnswers,
	active = '',
	showExperienceMessage,
}) => {
	const { t } = useTranslation();
	const [answers, setAnswers] = useState(cachedAnswers || []);
	const allQuestions: any = allQuestionsFile;
	const questions = allQuestions[group];
	const isNextDisabled = questions.length !== answers.length ? 'disabled' : '';

	const handleSetAnswer = (answer: any) => {
		if (answers.some((a: any) => a.question_id === answer.question_id)) {
			const answersArr = [...answers];
			const index = answersArr.findIndex((el: any) => el.question_id === answer.question_id);
			answersArr[index] = answer;
			setAnswers(answersArr);
		} else {
			setAnswers([...answers, answer]);
		}
	};

	function useCurrentWidth() {
		const [width, setWidth] = useState(window.innerWidth);

		useEffect(() => {
			const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			let timeoutId: any = null;
			const resizeListener = () => {
				clearTimeout(timeoutId);
				timeoutId = setTimeout(() => setWidth(getWidth()), 250);
			};
			window.addEventListener('resize', resizeListener);
			return () => {
				window.removeEventListener('resize', resizeListener);
			};
		}, []);

		return width;
	}

	const width = useCurrentWidth();
	const breakpoint = 767;

	const handleNext = () => {
		handleUpdateAnswers({ group, answers, questions_size: questions.length });
	};

	const questionDescription = description || t('components:questions.belowAreStatementsAboutYourself');
	return (
		<div className={`carousel-item ${active}`}>
			<div className="card text-center">
				<CardHeader title={title} subtitle={subtitle} href="#pageCarouselIndicator" />
				<div className="card-body">
					<ProgressBar completed={barValue + answers.length} />
					<div className="mt-auto mb-auto">
						<p className="card-text text-left" dangerouslySetInnerHTML={{ __html: questionDescription }} />
						{showExperienceMessage && (
							<p className="card-text text-left">
								{' '}
								{t('components:questions.pleaseSelectTheResponse')} <strong>{t('components:questions.twoWeeks')}</strong>.
							</p>
						)}
					</div>
					<br />
					{width > breakpoint ? (
						<TableQuestionnaire
							questions={questions}
							cachedAnswers={cachedAnswers}
							handleSetAnswer={handleSetAnswer}
						/>
					) : (
						<AccordionQuestionnaire
							questions={questions}
							cachedAnswers={cachedAnswers}
							handleSetAnswer={handleSetAnswer}
							group={group}
						/>
					)}
					{isNextDisabled && (
						<p
							style={{
								color: '#ea6944',
								marginBottom: 0,
								marginTop: 20,
								fontStyle: 'italic',
							}}
						>
							{t('components:questions.pleaseAnswerAllTheQuestions')}
						</p>
					)}
					<SliderButton handleClick={handleNext} isDisabled={isNextDisabled} />
				</div>
			</div>
		</div>
	);
};

export default Questions;

import React from 'react';
import { IInput } from '../../types';

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<HTMLInputElement, IInput>(
	(
		{
			label,
			htmlFor,
			children,
			...rest
		},
		ref,
	) => {
		if (label && htmlFor) {
			return (
				<label htmlFor={label}>
					<input ref={ref} {...rest} />
					{children}
				</label>
			);
		}
		return (
			<input ref={ref} {...rest} />
		);
	},
);

export default Input;

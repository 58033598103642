import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../hooks';
import {
	IAccordion, IAccordionQuestionnaire, IPushItem, IQuestion,
} from '../../types';
import Input from '../Input';

const Accordion: React.FC<IAccordion> = ({ title, children, accordionState = false }) => {
	const [isOpen, setOpen] = useState(accordionState);
	const { t } = useTranslation();
	const questionTString: any = `components:questions.allQuestions.${title}`;
	useEffect(() => {
		setOpen(accordionState);
	}, [accordionState]);
	return (
		<div className="accordion-wrapper">
			<div
				className={`accordion-title ${isOpen ? 'open' : ''}`}
				onKeyPress={() => setOpen(!isOpen)}
				onClick={() => setOpen(!isOpen)}
				role="button"
				tabIndex={0}
			>
				{t(questionTString)}
			</div>
			<div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
				<div className="accordion-content accordionBody">{children}</div>
			</div>
		</div>
	);
};

const AccordionQuestionnaire: React.FC<IAccordionQuestionnaire> = ({
	questions, cachedAnswers = [], handleSetAnswer, group,
}) => {
	const { t } = useTranslation();
	const [accordionState, setAccordionState] = useState({ [questions[0].id]: true });
	const labels = [
		t('components:accordionQuestionnaire.stronglyDisagree'),
		t('components:accordionQuestionnaire.somewhatDisagree'),
		t('components:accordionQuestionnaire.neutral'),
		t('components:accordionQuestionnaire.somewhatAgree'),
		t('components:accordionQuestionnaire.stronglyAgree'),
	];
	const labelsReversed = labels.slice().reverse();

	const handleAnswer = (e: any) => {
		const questionId = Number(e.target.attributes.getNamedItem('questionid').value);
		const answer = Number(e.target.value);
		handleSetAnswer({ question_id: questionId, answer });
		setAccordionState({ ...accordionState, [questionId]: false, [questionId + 1]: true });
	};

	const pushItem = ({
		question, answerValue, items, reverseLabel,
	}: IPushItem) => {
		const label = reverseLabel ? labelsReversed[answerValue - 1] : labels[answerValue - 1];
		const rowId = `${question.name}_${question.id}_${answerValue}`;
		const checked = cachedAnswers && cachedAnswers.find((ca: any) => ca.question_id === question.id && ca.answer === answerValue) ? 'checked' : '';
		items.push(
			<div key={rowId} className="text-left accordionLabel">
				<Input
					htmlFor={rowId}
					className="text-left accordionLabel"
					id={rowId}
					type="radio"
					defaultChecked={Boolean(checked)}
					name={question.name}
					questionid={question.id}
					value={answerValue}
					onClick={handleAnswer}
					label={rowId}
				>
					{label}
				</Input>
			</div>,
		);
	};

	return (
		<div className="accordion" id={`accordionQuestions_${group}`}>
			{questions.map((question) => {
				const items: IQuestion[] = [];
				if (question.reverse_scale) {
					for (let i = 5; i >= 1; i--) {
						pushItem({
							question, answerValue: i, items, reverseLabel: true,
						});
					}
				} else {
					for (let i = 1; i <= 5; i++) {
						pushItem({
							question, answerValue: i, items, reverseLabel: false,
						});
					}
				}

				return (
					<Accordion
						key={question.id}
						title={question.question}
						accordionState={accordionState[question.id]}
					>
						{items}
					</Accordion>
				);
			})}
		</div>
	);
};

export default AccordionQuestionnaire;

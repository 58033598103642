import React from 'react';
import Input from '../Input';
import { useTranslation } from '../../hooks';
import { IQuestion, ITableQuestionnaire } from '../../types';

interface IPushItem {
	question: IQuestion;
	answerValue: number;
	items: any[];
}

const TableQuestionnaire: React.FC<ITableQuestionnaire> = ({ questions, cachedAnswers = [], handleSetAnswer }) => {
	const { t } = useTranslation();

	const handleAnswer = (e: any) => {
		const questionId = Number(e.target.attributes.getNamedItem('questionid').value);
		const answer = Number(e.target.value);
		handleSetAnswer({ question_id: questionId, answer });
	};

	const pushItem = ({ question, answerValue, items }: IPushItem) => {
		const rowId = `${question.name}_${question.id}_${answerValue}`;
		const checked = cachedAnswers && cachedAnswers.find((ca: any) => ca.question_id === question.id && ca.answer === answerValue) ? 'checked' : '';
		items.push(
			<td key={rowId}>
				<Input
					htmlFor={rowId}
					id={rowId}
					type="radio"
					defaultChecked={Boolean(checked)}
					name={question.name}
					questionid={question.id}
					value={answerValue}
					onClick={handleAnswer}
					label={rowId}
				/>
			</td>,
		);
	};

	return (
		<div className="table-responsive">
			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col"> </th>
						<th scope="col">{t('components:tableQuestionnaire.stronglyDisagree')}</th>
						<th scope="col">{t('components:tableQuestionnaire.somewhatDisagree')}</th>
						<th scope="col">{t('components:tableQuestionnaire.neutral')}</th>
						<th scope="col">{t('components:tableQuestionnaire.somewhatAgree')}</th>
						<th scope="col">{t('components:tableQuestionnaire.stronglyAgree')}</th>
					</tr>
				</thead>
				<tbody>
					{questions.map((question: any) => {
						const questionTString: any = `components:questions.allQuestions.${question.question}`;
						const items: IQuestion[] = [];
						if (question.reverse_scale) {
							for (let i = 5; i >= 1; i--) {
								pushItem({ question, answerValue: i, items });
							}
						} else {
							for (let i = 1; i <= 5; i++) {
								pushItem({ question, answerValue: i, items });
							}
						}
						return (
							<tr key={question.id}>
								<td className="card-text text-left">{t(questionTString)}</td>
								{items}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default TableQuestionnaire;

import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../../hooks';

const Report: React.FC = () => {
	const { search } = useLocation();
	const { width, height } = useWindowSize();
	const [frameWidth, setFrameWidth] = useState(width);
	const [frameHeight, setFrameHeight] = useState(height);

	const reportUrl = useMemo(() => {
		const query = new URLSearchParams(search);
		return query.get('reportUrl');
	}, [search]);

	useEffect(() => {
		setFrameWidth(width);
		setFrameHeight(height);
	}, [height, width]);

	if (!reportUrl) {
		return <></>;
	}

	return (
		<object
			data={reportUrl}
			type="application/pdf"
			width={frameWidth}
			height={frameHeight}
		>
			<iframe
				title="Wellbeing Report"
				src={reportUrl}
				width={frameWidth}
				height={frameHeight}
			/>
		</object>
	);
};

export default Report;

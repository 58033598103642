import React from 'react';
import CardHeader from '../CardHeader';
import ProgressBar from '../ProgressBar';
import ContinueToWeb from '../ContinueToWeb/ContinueToWeb';
import { useTranslation } from '../../hooks';

interface ICompletionExit {
	active: string;
	barValue: number;
	result: {
		user_id: string;
		startedAt: Date;
		answers: any;
		finishedAt?: Date;
	}
}

const CompletionExit: React.FC<ICompletionExit> = ({ active = '', barValue = 0 }) => {
	const { t } = useTranslation();

	return (
		<div className={`carousel-item ${active}`}>
			<div className="card text-center">
				<CardHeader
					titleId={active !== '' ? 'wbs-exit-completed' : 'wbs-exit'}
					title={t('components:completionExit.headerTitle')}
				/>
				<div className="card-body">
					<ProgressBar completed={barValue} />
					<div className="mt-auto mb-auto">
						<p className="card-text text-left">{t('components:completionExit.weWillBeUsingYourResponses')}</p>
						<p className="card-text text-left">{t('components:completionExit.hangTight')}</p>
						<p className="card-text text-left">{t('components:completionExit.inTheMeantime')}</p>
						<p className="card-text text-left">{t('components:completionExit.thank')}</p>
						<p className="card-text text-left">
							{t('components:completionExit.ifMobile')} <a href="https://static.checkingin.co/app/">{t('components:completionExit.checkingHere')}</a>.
							{t('components:completionExit.ifDesktop')} <a href="https://web.checkingin.co/">{t('components:completionExit.webApp')}</a>.
						</p>
					</div>
					<ContinueToWeb />
				</div>
			</div>
		</div>
	);
};

export default CompletionExit;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Welcome from '../../components/Welcome';
import Consent from '../../components/Consent';
import CompletionBaseline from '../../components/CompletionBaseline';
import Summary from '../../components/Summary';
import Questions from '../../components/Questions';
import useStickyState from '../../hooks/useStickyState';
import Declining from '../../components/Declining';
import Spinner from '../../components/Spinner';
import { useTranslation } from '../../hooks';
import { useSurveyData } from '../../hooks/useSurveyData';
import { IAnswer, IParams } from '../../types';
import '../../css/questionnaire.scss';

interface IAnswers {
	group: string;
	questions_size: number;
	answers: IAnswer;
}

interface IResult {
	user_id: string;
	startedAt: Date;
	finishedAt?: Date;
	consent: boolean;
	answers: IAnswers;
}

const Baseline: React.FC = () => {
	const { userId } = useParams<IParams>();
	const { t } = useTranslation();
	const { baselineData, loading } = useSurveyData(userId);
	const [readyToSave, setReadyToSave] = useState(false);
	const [answers, setAnswers] = useStickyState([], `${userId}:ci_wbs_answers`);
	const [saved, setSaved] = useStickyState(false, `${userId}:ci_wbs_answers_saved`);
	const [consent, setConsent] = useStickyState(true, `${userId}:ci_wbs_consent`);
	const [startedAt, setStartedAt] = useStickyState(new Date(), `${userId}:ci_wbs_started_at`);

	const result: IResult = {
		user_id: userId,
		startedAt,
		consent,
		answers,
	};

	/*
	1. well_being
	2. self_reflection
	3. self_insight
	4. mindfulness
	5. life_satisfaction
	6. life_meaning_happiness
	7. life_engagement
	// Total of 6 as arrays are zero index based.
	*/
	const groupOfAnswers = 7;

	useEffect(() => {
		// This is a patch in case the user started the survey and did not complete and went back into it.
		if (saved && answers.length > 0 && answers.length < groupOfAnswers) {
			setSaved(false);
		}
	}, [saved, answers, setSaved]);

	useEffect(() => {
		if (baselineData) {
			setAnswers(baselineData.answers);
			setConsent(true);
			setStartedAt(baselineData.startedAt);
			if (baselineData.answers.length === groupOfAnswers) {
				setSaved(true);
			}
		}
	}, [baselineData, setAnswers, setConsent, setSaved, setStartedAt]);

	useEffect(() => {
		const saveBaseline = () => {
			result.finishedAt = new Date();

			axios.post(`${process.env.REACT_APP_WBS_API_URL}baseline`, result).then((res) => {
				console.log(res);
				console.log(res.data);
			});
			setSaved(true);
			setReadyToSave(false);
		};
		if (readyToSave && !saved) {
			saveBaseline();
		}
	}, [readyToSave, saved, result, setSaved]);

	const handleUpdateAnswers = (answer: any) => {
		if (answers.some((a: any) => a.group === answer.group)) {
			const answersArr = [...answers];
			const index = answersArr.findIndex((el: any) => el.group === answer.group);
			answersArr[index] = answer;

			setAnswers(answersArr);
		} else {
			setAnswers([...answers, answer]);
		}

		// Auto saves when reaching life engagement, that way we don't need a save button
		if (answer.group === 'life_engagement' && !saved) {
			setReadyToSave(true);
		}
	};

	useEffect(() => {
		if (!consent && !saved) {
			setReadyToSave(true);
		}
	}, [consent, saved]);

	let activeTab;

	if (!answers.some((a: any) => a.group === 'well_being')) {
		activeTab = 'welcome';
	} else if (!answers.some((a: any) => a.group === 'self_reflection')) {
		activeTab = 'self_reflection';
	} else if (!answers.some((a: any) => a.group === 'self_insight')) {
		activeTab = 'self_insight';
	} else if (!answers.some((a: any) => a.group === 'mindfulness')) {
		activeTab = 'mindfulness';
	} else if (!answers.some((a: any) => a.group === 'life_satisfaction')) {
		activeTab = 'life_satisfaction';
	} else if (!answers.some((a: any) => a.group === 'life_meaning_happiness')) {
		activeTab = 'life_meaning_happiness';
	} else if (!answers.some((a: any) => a.group === 'life_engagement')) {
		activeTab = 'life_engagement';
	} else {
		activeTab = 'completion';
	}

	function getAnswers(group: any) {
		return answers.some((a: any) => a.group === group) ? answers.find((ca: any) => ca.group === group).answers : null;
	}

	const wellbeingAnswers = getAnswers('well_being');
	const selfReflectionAnswers = getAnswers('self_reflection');
	const selfInsightAnswers = getAnswers('self_insight');
	const mindfulnessAnswers = getAnswers('mindfulness');
	const lifeSatisfactionAnswers = getAnswers('life_satisfaction');
	const lifeMeaningHappinessAnswers = getAnswers('life_meaning_happiness');
	const lifeEngagementAnswers = getAnswers('life_engagement');

	const welcomeContent = {
		title: t('pages:baseline.welcomToTheCheckingIn'),
		description: [
			t('pages:baseline.thisProjectIsACollaboration'),
			t('pages:baseline.beforeWebegin'),
		],
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<section className="questionnaire">
			<div className="container">
				<div id="pageCarouselIndicator" className="carousel slide" data-interval="false" data-touch="false">
					<ol className="carousel-indicators">
						{consent && !saved && (
							<>
								<li data-target="#pageCarouselIndicator" className={!answers.length ? 'active' : ''} />
								<li data-target="#pageCarouselIndicator" />
								<li data-target="#pageCarouselIndicator" className={activeTab === 'well_being' ? 'active' : ''} />
								<li data-target="#pageCarouselIndicator" className={activeTab === 'self_reflection' ? 'active' : ''} />
								<li data-target="#pageCarouselIndicator" className={activeTab === 'self_insight' ? 'active' : ''} />
								<li data-target="#pageCarouselIndicator" className={activeTab === 'mindfulness' ? 'active' : ''} />
								<li data-target="#pageCarouselIndicator" className={activeTab === 'life_satisfaction' ? 'active' : ''} />
								<li data-target="#pageCarouselIndicator" className={activeTab === 'life_meaning_happiness' ? 'active' : ''} />
								<li data-target="#pageCarouselIndicator" className={activeTab === 'life_engagement' ? 'active' : ''} />
								<li data-target="#pageCarouselIndicator" />
								<li data-target="#pageCarouselIndicator" />
							</>
						)}
					</ol>
					<div className="carousel-inner card">
						{!consent && <Declining callbackConsent={setConsent} callbackSaved={setSaved} />}
						{consent && (
							<>
								<Welcome active={activeTab === 'welcome' ? 'active' : ''} content={welcomeContent} />
								<Consent callbackConsent={setConsent} />
								<Questions
									active={activeTab === 'well_being' ? 'active' : ''}
									cachedAnswers={wellbeingAnswers}
									title={t('pages:baseline.wellBeing')}
									subtitle={`${t('pages:baseline.questions')} 1 ${t('pages:baseline.through')} 14`}
									group="well_being"
									showExperienceMessage
									barValue={0}
									handleUpdateAnswers={handleUpdateAnswers}
								/>
								<Questions
									active={activeTab === 'self_reflection' ? 'active' : ''}
									cachedAnswers={selfReflectionAnswers}
									title={t('pages:baseline.selfReflection')}
									subtitle={`${t('pages:baseline.questions')} 15 ${t('pages:baseline.through')} 26`}
									group="self_reflection"
									barValue={15}
									handleUpdateAnswers={handleUpdateAnswers}
								/>
								<Questions
									active={activeTab === 'self_insight' ? 'active' : ''}
									cachedAnswers={selfInsightAnswers}
									title={t('pages:baseline.selfInsight')}
									subtitle={`${t('pages:baseline.questions')} 27 ${t('pages:baseline.through')} 34`}
									group="self_insight"
									barValue={30}
									handleUpdateAnswers={handleUpdateAnswers}
								/>
								<Questions
									active={activeTab === 'mindfulness' ? 'active' : ''}
									cachedAnswers={mindfulnessAnswers}
									title={t('pages:baseline.mindfulness')}
									subtitle={`${t('pages:baseline.questions')} 35 ${t('pages:baseline.through')} 49`}
									group="mindfulness"
									barValue={45}
									handleUpdateAnswers={handleUpdateAnswers}
								/>
								<Questions
									active={activeTab === 'life_satisfaction' ? 'active' : ''}
									cachedAnswers={lifeSatisfactionAnswers}
									title={t('pages:baseline.lifeSatisfaction')}
									subtitle={`${t('pages:baseline.questions')} 50 ${t('pages:baseline.through')} 54`}
									group="life_satisfaction"
									barValue={60}
									handleUpdateAnswers={handleUpdateAnswers}
								/>
								<Questions
									active={activeTab === 'life_meaning_happiness' ? 'active' : ''}
									cachedAnswers={lifeMeaningHappinessAnswers}
									title={t('pages:baseline.lifeMeaning')}
									subtitle={`${t('pages:baseline.questions')} 55 ${t('pages:baseline.through')} 60`}
									group="life_meaning_happiness"
									barValue={75}
									handleUpdateAnswers={handleUpdateAnswers}
								/>
								<Questions
									active={activeTab === 'life_engagement' ? 'active' : ''}
									cachedAnswers={lifeEngagementAnswers}
									title={t('pages:baseline.lifeEngagement')}
									subtitle={`${t('pages:baseline.questions')} 61 ${t('pages:baseline.through')} 66`}
									group="life_engagement"
									barValue={90}
									handleUpdateAnswers={handleUpdateAnswers}
								/>
								<CompletionBaseline
									result={result}
									active={activeTab === 'completion' ? 'active' : ''}
									barValue={100}
								/>
								{activeTab === 'completion' && <Summary result={result} saved={saved} />}
							</>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Baseline;

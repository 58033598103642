import React from 'react';
import { IProgressBar } from '../../types';

const ProgressBar: React.FC<IProgressBar> = ({ completed }) => {
	const containerStyles = {
		height: 5,
		width: '100%',
		backgroundColor: '#e8e8eb',
		borderRadius: 10,
		marginTop: 20,
		marginBottom: 40,
	};
	const fillerStyles: any = {
		transition: 'width 1s ease-in-out',
		height: '100%',
		width: `${completed}%`,
		backgroundColor: '#3c7778',
		borderRadius: 'inherit',
		textAlign: 'right',
	};

	return (
		<div style={containerStyles}>
			<div style={fillerStyles} />
		</div>
	);
};

export default ProgressBar;

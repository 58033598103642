import React from 'react';
import CardHeader from '../CardHeader';
import SliderButton from '../SliderButton';
import { IWelcome } from '../../types';

const Welcome: React.FC<IWelcome> = ({ active = '', content }) => (
	<div className={`carousel-item ${active}`}>
		<div className="card text-center">
			<CardHeader title={content.title} />
			<div className="card-body">
				<div className="mt-auto mb-auto">
					{content.description.map((paragraph, index) => (
						<p className="card-text text-left" key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
					))}
					<div className="mt-5 mb-auto">
						<img src="/checkingin-ubc-logos.png" alt="CheckingIn and UBC logos" height="80" />
					</div>
				</div>
				<br />
				<SliderButton />
			</div>
		</div>
	</div>
);

export default Welcome;

import React from 'react';
import { useTranslation } from '../../hooks';

const DownloadAppIcons: React.FC = () => {
	const { t } = useTranslation();
	return (
		<div className="row d-flex justify-content-center">
			<div className="column">
				<a href="https://play.google.com/store/apps/details?id=com.checkingin" target="_blank" rel="noopener noreferrer">
					<img
						alt={t('components:downloadAppIcons.getItOnGooglePlay')}
						src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
						style={{ height: 60 }}
					/>
				</a>
			</div>
			<div className="column">
				<a href="https://apps.apple.com/us/app/checkingin/id1465984722" target="_blank" rel="noopener noreferrer">
					<img
						alt={t('components:downloadAppIcons.getItOnAppStore')}
						src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
						style={{ paddingTop: 10 }}
					/>
				</a>
			</div>
		</div>
	);
};

export default DownloadAppIcons;

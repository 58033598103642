import React from 'react';
import { useTranslation } from '../../hooks';
import scrollToTop from '../../shared/scrollToTop';
import { ISliderButton } from '../../types';

const SliderButton: React.FC<ISliderButton> = ({
	hrefLink = '#pageCarouselIndicator',
	label,
	handleClick = () => { },
	isDisabled = '',
}) => {
	const { t } = useTranslation();
	const sliderButtonClickHandle = () => {
		handleClick();
		scrollToTop();
	};
	return (
		<a
			href={hrefLink}
			onClick={sliderButtonClickHandle}
			role="button"
			data-slide="next"
			className={`align-self-end btn btn-primary ci-btn-orange ${isDisabled}`}
		>
			{label || t('components:sliderButton.next')}
		</a>
	);
};
export default SliderButton;

import React from 'react';
import { useTranslation } from '../../hooks';
import { IDeclining } from '../../types';
import CardHeader from '../CardHeader';
import DownloadAppIcons from '../DownloadAppIcons';

const Declining: React.FC<IDeclining> = ({ callbackConsent, callbackSaved }) => {
	const { t } = useTranslation();

	function handleStartOver() {
		callbackConsent(true);
		callbackSaved(false);
	}
	return (
		<div className="carousel-item active">
			<div className="card text-center">
				<CardHeader title={t('components:declining.headerTitle')} />
				<div className="card-body">
					<div className="mt-auto mb-auto">
						<p className="card-text text-left">
							{t('components:declining.youHaveDeclineToParticipate')}
						</p>
						<p className="card-text text-left">
							{t('components:declining.ifYouHaveChangedYourMind')}{' '}
							<a href="#pageCarouselIndicator" role="button" data-slide="prev" onClick={handleStartOver}>
								{t('components:declining.here')}
							</a>{' '}
							{t('components:declining.toStartOver')}
						</p>
						<p className="card-text text-left">
							{t('components:declining.youMayStillDownload')}
						</p>
						<DownloadAppIcons />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Declining;

import React from 'react';
import CardHeader from '../CardHeader';
import ProgressBar from '../ProgressBar';
import SliderButton from '../SliderButton';
import { ICompletionBaseLine } from '../../types';
import { useTranslation } from '../../hooks';

const CompletionBaseLine: React.FC<ICompletionBaseLine> = ({
	active = '',
	barValue = 0,
}) => {
	const { t } = useTranslation();

	return (
		<div className={`carousel-item ${active}`}>
			<div className="card text-center">
				<CardHeader
					titleId={active !== '' ? 'wbs-baseline-completed' : 'wbs-baseline'}
					title={t('components:completionBaseLine.headerTitle')}
				/>
				<div className="card-body">
					<ProgressBar completed={barValue} />
					<div className="mt-auto mb-auto">
						<p className="card-text text-left">
							{t('components:completionBaseLine.thePurposeOfThisIndex')}
							{t('components:completionBaseLine.followingTheCompletion')}
						</p>
						<p className="card-text text-left">{t('components:completionBaseLine.ifYouWouldLikeToSee')}</p>
					</div>
					<br />
					<SliderButton label={t('components:completionBaseLine.summary')} />
				</div>
			</div>
		</div>
	);
};

export default CompletionBaseLine;

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from '../../hooks';
import { IComplexQuestion } from '../../types';
import Input from '../Input';

const ComplexQuestion: React.FC<IComplexQuestion> = ({ question, handleSetAnswer }) => {
	const { t } = useTranslation();
	const otherInput = useRef<any>(null);
	const [isOtherInputVisible, setIsOtherInputVisible] = useState(false);
	const handleRadio = (e: any) => {
		setIsOtherInputVisible(false);
		handleSetAnswer(e);
	};
	const handleOtherRadio = (e: any) => {
		setIsOtherInputVisible(true);
		handleSetAnswer(e);
	};

	useEffect(() => {
		if (isOtherInputVisible) {
			otherInput.current.focus();
		}
	}, [isOtherInputVisible]);

	const questionTString: any = `components:demographics.questions.${question.question}`;

	return (
		<div className="form-group text-left">
			<h6 className="mb-0 card-text">{t(questionTString)}</h6>
			{question.answers.map((answer, i) => {
				const rowId = `${answer.name}_${question.id}_${i}`;
				const labelTString: any = `components:demographics.questions.${answer.label}`;
				if (answer.type === 'input') {
					return (
						<div className="form-group text-left" key={rowId}>
							<Input
								type="text"
								className="form-control"
								maxLength={answer.maxLength}
								id={rowId}
								onChange={handleSetAnswer}
								questionid={question.id}
							/>
						</div>
					);
				}
				if (answer.type === 'radio') {
					return (
						<div className="form-check ml-2" key={rowId}>
							<Input
								className="form-check-input"
								type="radio"
								onClick={handleRadio}
								questionid={question.id}
								name={answer.name}
								id={rowId}
								value={answer.value}
							/>
							<label className="form-check-label" htmlFor={rowId}>
								{t(labelTString)}
							</label>
						</div>
					);
				}
				if (answer.type === 'radio_other') {
					const radioOtherLabelTString: any = `components:demographics.questions.${answer.label}`;
					const otherInputLabelTString: any = `components:demographics.questions.${answer.other_input_label}`;
					return (
						<div className="form-check ml-2" key={rowId}>
							<Input
								className="form-check-input"
								type="radio"
								onClick={handleOtherRadio}
								questionid={question.id}
								name={answer.name}
								id={rowId}
								value={answer.value}
							/>
							<label className="form-check-label" htmlFor={rowId}>
								{answer.label
									? t(radioOtherLabelTString)
									: t('components:complexQuestion.noneOfAbove')}{' '}
								{isOtherInputVisible && answer.other_input_label
									? t(otherInputLabelTString)
									: t('components:complexQuestion.ifYouFeelComfortable')}
							</label>
							{isOtherInputVisible && (
								<div className="form-group mt-2">
									<Input
										ref={otherInput}
										type="text"
										width="100%"
										className="form-control form-"
										id={`${rowId}_other`}
										maxLength={answer.maxLength}
										questionid={question.id}
										onChange={handleSetAnswer}
									/>
								</div>
							)}
						</div>
					);
				}
				return null;
			})}
		</div>
	);
};

export default ComplexQuestion;

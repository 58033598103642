import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../hooks';
import { availableLanguages } from '../../i18n';
import './Navbar.scss';

const Navbar: React.FC = () => {
	const { switchLanguage, userLanguage, t } = useTranslation();
	const [language, setLanguage] = useState(userLanguage);

	const handleChangeLanguage = (lang: string): void => {
		switchLanguage(lang);
		setLanguage(lang);
	};

	useEffect(() => {
		setLanguage(userLanguage);
	}, [userLanguage]);

	return (
		<nav className="navbar navbar-dark navbar-expand-lg navbar-bg sticky-top">
			<a
				className="navbar-brand"
				href="https://www.checkingin.co/download-app/"
				target="_blank"
				rel="noopener noreferrer"
				title={t('components:navbar.getCheckingIn')}
			>
				<img alt="" src="/logo.png" height="40" className="d-inline-block align-top" />{' '}
			</a>
			<div className="lng-container">
				{availableLanguages.map(lng => (
					<button
						key={lng.code}
						type="button"
						onClick={() => handleChangeLanguage(lng.code)}
						className={`btn-lng ${language === lng.code && 'btn-active'}`}
					>
						{lng.name.substring(0, 2)}
					</button>
				))}
			</div>
		</nav>
	);
};

export default Navbar;

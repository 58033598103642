import React, { useState } from 'react';
import { useTranslation } from '../../hooks';
import { IConsent } from '../../types';
import CardHeader from '../CardHeader';
import SliderButton from '../SliderButton';

const Consent: React.FC<IConsent> = ({ callbackConsent }) => {
	const { t } = useTranslation();
	const [consent, setConsent] = useState(true);
	function handleConsentDeclined() {
		setConsent(false);
	}
	function handleNext() {
		callbackConsent(consent);
	}

	return (
		<div className="carousel-item">
			<div className="card text-center">
				<CardHeader title={t('components:consent.headerTitle')} href="#pageCarouselIndicator" />
				<div className="card-body">
					<div className="mt-auto mb-5">
						<img src="/UBC-logo-psych.png" alt="UBC Credential" height="80" />
						<p className="mb-0">{t('components:consent.ubc').toUpperCase()}</p>
						<p>
							<small>{t('components:consent.departmentOfPsychology')}</small>
						</p>
					</div>
					<div className="mt-auto mb-4">
						<h5>{t('components:consent.title')}</h5>
						<p className="card-text text-left">
							<strong>{t('components:consent.principalInvestigator')}:</strong> {t('components:consent.prof')} James Enns
							<br />
							{t('components:consent.departmentOfPsychology')}
							<br />
							{t('components:consent.ubc')}
							<br />
							{t('components:consent.phone')}: 604-822-6634
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.coInvestigator')}:</strong> Pavel Kozik, {t('components:consent.graduateStudent')}
							<br />
							{t('components:consent.departmentOfPsychology')}
							<br />
							{t('components:consent.ubc')}
							<br />
							{t('components:consent.phone')}: 604-822-6634
							<br />
							{t('components:consent.email')}: <a href="mailto:pavelkozik@psych.ubc.ca">pavelkozik@psych.ubc.ca</a>
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.purpose')}:</strong>
							<br />
							{t('components:consent.purposeContent')}
						</p>
						<p className="card-text text-left mb-0">
							<strong>{t('components:consent.studyProcedures')}:</strong>
							<br />
							{t('components:consent.studyProceduresContentFirstPart')}
						</p>
						<p className="card-text text-left">
							{t('components:consent.studyProceduresContentSecondPart')}
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.potentialRisks')}:</strong>
							<br />
							{t('components:consent.potentialRisksContent')}
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.potentialBenefits')}:</strong>
							<br />
							{t('components:consent.potentialBenefitsContent')}
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.confidentiality')}:</strong>
							<br />
							{t('components:consent.confidentialityContent')}
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.relationship')}:</strong>
							<br />
							{t('components:consent.relationshipContent')}
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.contact')}:</strong>
							<br />
							{t('components:consent.contactContent')}
							<a href="mailto:pavelkozik@psych.ubc.ca"> pavelkozik@psych.ubc.ca</a>
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.contactForConcerns')}:</strong>
							<br />
							{t('components:consent.contactForConcernsContent')}
							<a href="mailto:RSIL@ors.ubc.ca"> RSIL@ors.ubc.ca</a> {t('components:consent.orCall')} 1-877-822-8598.
						</p>
						<p className="card-text text-left">
							<strong>{t('components:consent.consent')}:</strong>
							<br />
							{t('components:consent.consentContent')}
						</p>
					</div>
					<div className="sexy_line" />
					<div className="mt-3 mb-auto">
						<p className="card-text text-left">{t('components:consent.havingRead')}:</p>
						<form className="card-text text-left">
							<div className="form-check">
								<label htmlFor="agree">
									<input
										type="radio"
										id="agree"
										name="consent"
										value="agree"
										className="form-check-input"
									/>
									{t('components:consent.iVoluntarilyAgree')}
								</label>
							</div>

							<div className="form-check">
								<label htmlFor="decline">
									<input
										id="decline"
										type="radio"
										name="consent"
										onClick={handleConsentDeclined}
										value="decline"
										className="form-check-input"
									/>
									{t('components:consent.iDecline')}
								</label>
							</div>
						</form>
					</div>
					<SliderButton handleClick={handleNext} />
				</div>
			</div>
		</div>
	);
};

export default Consent;
